import React from 'react';

const Headshot = () => {
    return (
        <svg className="paintedHeadshot" width="2353" height="2610" viewBox="0 0 2353 2610" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M928 115L914 146.556L914 291L928 303L986 317L1032 291L1070 303L1128 303L1186 317L1222 341L1278 403L1404 591L1438 695L1468 835L1478 925L1458 957L1418 1067L1336 1181L1244 1273L1244 1369L1214 1483L1214 1547L1186 1669L1242 1837L1204 1961L1216 2091L1204 2227L1186 2413L1126 2485L1186 2485L1296 2449L1366 2375L1486 2227L1564 2227L1594 2163L1594 2043L1634 1907L1634 1803L1614 1715L1634 1641L1614 1507L1592 1423L1614 1307L1580 1197L1634 1109L1614 1017L1580 925L1590 835L1580 725L1554 623L1510 539L1510 431L1468 343L1404 265L1268 146.556L1116 84.9999L998 84.9998L928 115Z" fill="#A86B89"/>
            <path d="M1406 592.999L1326 482.999L1280 407L1233 361L1186 315L1124 305L1074 305L1030 294.665L986 315L922 315L890 341L860 407L836 499L782 719L799 830L808 951L836 1035L902 1121L1086 1259L1168 1287L1262 1259L1326 1191L1418 1073L1452 983L1478 933L1464 817L1440 694.999L1406 592.999Z" fill="#E3D1BE"/>
            <path d="M782.001 708.997L842.001 468.997L884.214 350.997L924.5 307.021L916 141L860 153L746 269L706 329L644 573L612 625L600 681L612 763L612 819L593 881L574 943L558 1013L574 1085L574 1153L530 1219L514 1303L530 1365L558 1445L558 1485L530 1579L530 1667L558 1775L558 1815L530 1929L542 1989L602 2111L602 2169L576 2237L588 2281L616 2339L708 2431L708 2527L748 2431L806 2431L822 2541L914 2503L958 2405L978 2451L978 2575L1020 2493L1038 2339L1090 2255L1056 2155L1072 2031L1038 1859L1072 1815L1090 1745L1072 1681L1026 1643L1010 1597L1010 1537L1038 1445L1026 1387L936 1259L906 1131L812.001 954.997L782.001 708.997Z" fill="#A86B89"/>
            <path d="M1418.45 608.494L1380.39 576.603L1348.37 576.932L1258.23 624.261L1281.51 642.18L1380.15 598.799L1418.45 608.494Z" fill="#A86B89"/>
            <path d="M886 661L930 615L988 597H1054L1114 625L1100 646.5L1030 625H960L886 661Z" fill="#A86B89"/>
            <path d="M1034 695L1072 717L1098 747L1050 747L984 747L918 731L984 707L1034 695Z" fill="white"/>
            <path d="M1431.9 704.509L1406 681L1346 681L1306 704.509L1278 737L1318 737L1376 737L1431.9 704.509Z" fill="white"/>
            <path d="M1106 1041L1009 1024.5L1048 1053L1112 1087L1194 1095L1262 1077L1320 1024.5L1222 1041L1106 1041Z" fill="white"/>
            <line x1="1009" y1="1022.5" x2="1010" y2="1022.5" stroke="black"/>
            <path d="M1266 1011L1208 1022.5L1169 1016L1093 1022.5H1000L1128 1043H1218L1293 1030L1327 1016L1266 1011Z" fill="#D56261"/>
            <path d="M1326.99 1017.5L1298 1047L1254 1080L1218 1090L1194 1096L1136 1090L1109 1090L1084 1073L1053 1056L1008.99 1025L1053 1080L1104 1122L1142 1140L1218 1140L1254 1132L1279 1110L1309 1066L1326.99 1017.5Z" fill="#D56261"/>
            <path d="M896.807 1116.46L1042 1232L1086 1267L1170 1288L1247 1267L1230 1345L1236 1375L1188 1415L1111 1457L1073 1457L1042 1432L1035 1403L1008 1350L937 1246L922 1171L896.807 1116.46Z" fill="#E3D1BE"/>
            <ellipse cx="1014.5" cy="725" rx="30.5" ry="22" fill="#593B2D"/>
            <ellipse cx="1337.5" cy="711.5" rx="25.5" ry="22.5" fill="#593B2D"/>
            <path d="M1101.04 738.502L1039 706L1003 701L954 723L915 733L976 706L1031 694L1069 714L1101.04 738.502Z" fill="#057196"/>
            <path d="M1308 702.51L1279.9 733.51L1312 707L1345 690L1377 690L1433 707L1403 682L1377 682L1345 682L1308 702.51Z" fill="#057196"/>
            <path d="M1424 710C1428.34 708.307 1430.36 705.821 1434 704" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Headshot;